import { Container, Section } from '@components/common'
import Head from '@components/Head'
import Button from '@mrs-ui/Button'
import React from 'react'
const NotFoundPage = () => {
  return (
    <>
      <Head
        title="Page not found"
        description="Sorry, we couldn't find the page you were looking for."
      />
      <Section>
        <Container>
          <div className="w-full text-center flex flex-col items-center justify-center">
            <h2>Sorry, we couldn't find that page.</h2>
            <Button className="mt-gap-default" appearance="primary" link="/">
              Go home
            </Button>
          </div>
        </Container>
      </Section>
    </>
  )
}
export default NotFoundPage
